<template>
  <div class="submission-card-holder">
    <div
      class="submission-card"
      @click="$emit('edit', submission)"
      :class="getCardColor"
    >
      <v-row class="d-flex pa-3">
        <div class="max-70">
          <div class="pa-0 ma-0 project-name">
            {{ submission.tailboardFormHeader.projectName }}
          </div>
          <p class="caption mt-1">{{ submission.insertedAt | parseDate }}</p>
        </div>
        <v-spacer></v-spacer>
        <div v-if="isClaimedByMe" class="claim-status">
          <v-icon large>lock</v-icon>
        </div>
        <div class="mr-15">
          <div class="d-flex mb-1" style="direction: rtl">
            <v-avatar size="12" :class="getStateCircle(3)"></v-avatar>
            <div :class="getStateLine(3)" />
            <v-avatar size="12" :class="getStateCircle(2)"></v-avatar>
            <div :class="getStateLine(2)" />
            <v-avatar size="12" :class="getStateCircle(1)"></v-avatar>
            <div :class="getStateLine(1)" />
            <v-avatar size="12" :class="getStateCircle(0)"></v-avatar>
          </div>
          <div style="text-align: center !important">
            <p class="p-state">
              {{ pascalCase(submission.state) }}
            </p>
            <p class="p-mode">
              <span class="secondary pa-1">{{ submission.mode }}</span>
            </p>
          </div>
        </div>
      </v-row>
      <v-row class="mt-n2">
        <v-col>
          <p class="p-title">WORKSITE LOCATION</p>
          <p class="p-text max-95">
            {{ submission.tailboardFormHeader.location }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-n4">
        <v-col class="max-50">
          <p class="p-title">ONSITE LEADER</p>
          <p class="p-text">{{ submission.user.name }}</p>
        </v-col>
        <v-col class="max-50">
          <p class="p-title">DEPARTMENT</p>
          <p class="p-text">{{ submission.tailboardFormHeader.dept }}</p>
        </v-col>
        <v-col> </v-col>
      </v-row>
      <v-row class="mt-n4">
        <v-col>
          <p class="p-title">WORK ORDER #</p>
          <p class="p-text max-30">
            {{ submission.tailboardFormHeader.workOrderNumber }}
          </p>
        </v-col>
        <v-col>
          <p class="p-title">Cost Center #</p>
          <p class="p-text max-30">
            {{ submission.tailboardFormHeader.rcNumber }}
          </p>
        </v-col>
        <v-col>
          <p class="p-title">WBS #</p>
          <p class="p-text max-30">{{ submission.tailboardFormHeader.wbs }}</p>
        </v-col>
        <v-col v-if="submission.isLocked">
          <div class="d-flex">
            <v-icon class="mr-1 mt-1" color="primary"
              >$vuetify.icons.lock</v-icon
            >
            <div>
              <p class="p-title">LOCKED BY</p>
              <p class="p-text max-30">
                {{ submission.isLockedBy ? submission.isLockedBy.name : "" }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row
      class="submission-card-footer"
      :class="hasUserChildForm ? 'has-child' : 'secondary'"
    >
      <v-col cols="9">
        <v-select
          class="white"
          hide-details
          dense
          outlined
          :label="
            `Child forms [ ${completeChildForms} / ${
              submission.childTailboardFormSubmissions
                ? submission.childTailboardFormSubmissions.length
                : 0
            } complete ]`
          "
          v-model="selectedChild"
          :items="childFormsItems"
          @change="$router.push(selectedChild)"
        >
          <template v-slot:item="{ item }">
            <v-row class="ma-0 select-existing-child-form__item">
              <v-icon
                class="mr-4 align-start"
                :color="item.state === 'COMPLETED' ? 'primary' : 'base'"
                >{{
                  item.state === "COMPLETED"
                    ? "check_circle_outline"
                    : "radio_button_unchecked"
                }}</v-icon
              >
              <v-col class="pa-0">
                <p class="ma-0 mb-1" style="flex-basis: 100%">
                  {{ item.text || "" }}
                </p>
                <v-row align="end" justify="space-between" class="ma-0">
                  <span class="caption ma-0 ml-4">
                    Owner: {{ item.owner || "" }}
                  </span>
                  <span class="caption flex-end"
                    >[ {{ pascalCase(item.state) }} ]</span
                  >
                </v-row>
              </v-col>
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-icon
          class="mr-4 float-right"
          v-if="
            (submission.state === 'SUBMITTED' ||
              submission.state === 'ARCHIVED') &&
              submission.id
          "
          size="50"
          @click="$emit('pdf', submission.id)"
          >$vuetify.icons.download</v-icon
        ></v-col
      >
    </v-row>
  </div>
</template>
<script>
import utils from "@/shared/Utils";
import dayjs from "@/plugins/dayjs";
import { isClaimedByMe } from "@/utilities/submission.js";

export default {
  name: "SubmissionCard",

  props: ["submission"],

  data() {
    return {
      selectedChild: "",
      openComment: false
    };
  },

  filters: {
    parseDate(val) {
      return dayjs
        .utc(val)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    }
  },
  computed: {
    isClaimedByMe() {
      return isClaimedByMe(this.submission, this.user);
    },
    getCardColor() {
      const isGray =
        this.user &&
        this.user.role === "SUPERVISOR" &&
        this.submission.user.id !== this.user.id &&
        !this.submission.crewMembers.some(
          e => e.employee.id === this.user.employee.id
        ) &&
        this.user.employee.departmentRef ===
          this.submission.user.employee.departmentRef &&
        this.submission.supervisorId !== this.user.employee.id;
      return isGray ? "inactive" : "white";
    },
    user() {
      return this.$store.getters.getUser;
    },
    hasChildForm() {
      return (
        this.submission &&
        this.submission.childTailboardFormSubmissions &&
        this.submission.childTailboardFormSubmissions.length > 0
      );
    },
    hasUserChildForm() {
      return (
        this.childFormsItems.filter(
          c =>
            this.user && c.owner === this.user.name && c.state !== "COMPLETED"
        ).length > 0
      );
    },
    childFormsItems() {
      return this.submission.childTailboardFormSubmissions
        ? this.submission.childTailboardFormSubmissions
            .map((c, i) => {
              return {
                value:
                  (this.submission.mode === "CLOSED" &&
                    this.submission.state === "SUBMITTED") ||
                  this.submission.state === "ARCHIVED"
                    ? `/preview/${this.submission.id}/${this.submission.state}/${i}`
                    : `/forms/${c.formId}/submit/${c.id}`,
                text: `${c.tailboardForm.title} -${dayjs
                  .utc(c.insertedAt)
                  .local()
                  .format("HH:mm")}`,
                disabled:
                  this.user &&
                  this.user.role === "USER" &&
                  (this.user.id !== c.user.id ||
                    (this.user.id === c.user.id &&
                      c.isLocked &&
                      c.isLockedBy.id !== this.user.id)),
                title: c.title,
                owner: c.user ? c.user.name : "",
                state: c.state,
                created: c.insertedAt
              };
            })
            .sort((a, b) => {
              return new Date(b.created) - new Date(a.created);
            })
        : [];
    },
    completeChildForms() {
      return this.childFormsItems.filter(s => s.state === "COMPLETED").length;
    },
    currentPosition() {
      switch (this.submission.state) {
        case "IN_PROGRESS":
          return 0;
        case "COMPLETED":
          return 1;
        case "SIGNED_OFF":
          return 2;
        case "SUBMITTED":
          return 3;
        case "ARCHIVED":
          return 4;
        default:
          return 0;
      }
    }
  },

  methods: {
    pascalCase(str) {
      return utils.toPascalCase(str);
    },
    getStateCircle(position) {
      return this.currentPosition >= position ? "primary" : "secondary";
    },
    getStateLine(position) {
      return this.currentPosition >= position ? "div-line-active" : "div-line";
    }
  }
};
</script>
<style scoped>
.project-name {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.p-state {
  width: 80px !important;
  text-align: center !important;
}

.max-30 {
  max-width: 200px !important;
}

.max-50 {
  max-width: 50%;
}

.max-95 {
  max-width: 95%;
}

.max-70 {
  max-width: 70%;
}

.p-mode {
  width: auto !important;
  font-weight: bold;
  text-align: center !important;
  color: var(--v-primary-base);
  font-size: 10px;
}

.submission-card-holder {
  box-shadow: 0 2px 5px 0 var(--v-border-base) !important;
  background-color: white;
  border: 1px solid var(--v-border-base) !important;
  position: relative;
  margin: 6.5px;
  position: relative;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.p-text {
  font-size: 16px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.p-title,
.p-state {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: var(--v-primary-base);
}
.div-line-active {
  border-top: 2px solid var(--v-primary-base);
}
.div-line,
.div-line-active {
  border-top: 2px solid var(--v-primary-base);
  width: 12px;
  margin-top: 5px;
}
.div-line {
  border-top: 2px solid var(--v-border-base);
}

.submission-card-footer {
  height: 61px !important;
  margin: 0px;
}

.submission-card {
  padding: 16px 16px 0 16px;
}

.has-child {
  background-color: rgba(238, 175, 48, 0.2);
}

.select-existing-child-form__item {
  border-bottom: 1px solid #ebebeb;
}

.claim-status {
  display: flex;
  margin-right: 10px;
  justify-content: center;
}
</style>
